import * as Sentry from "@sentry/browser"

Sentry.init({
	dsn: "https://848f9235a597641a19b13f258ee0d831@o4508026140622848.ingest.us.sentry.io/4508048373317632",

	environment: import.meta.env.PROD ? "production" : "local",

	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.1,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ["localhost", "https://renewal.app"],

	// Capture Replay for 1% of all sessions...
	replaysSessionSampleRate: 0.01,

	// ...plus for 5% of sessions with an error
	replaysOnErrorSampleRate: 0.05,
})
